<template>
  <div>
    <div class="head1">
      <div class="headContent">
        <div class="headLogo">
          <img style="width: 150px" src="@/assets/headLogoB.png" alt="" />
        </div>
        <div class="headTab">
          <div
            class="tabmr"
            :class="winHeight > 0 ? 'tabmrCol' : ''"
            @click="goHome"
          >
            <div class="tabFont tabFont1">首页</div>
            <div class="tabmr1"></div>
          </div>
          <div class="tabmr" :class="winHeight > 0 ? 'tabmrCol' : ''">
            <div class="tabFont">
              <span class="tabFont1" @click="goSolution">解决方案</span>
              <div class="tabmr1"></div>
              <div class="jjfaTab">
                <div class="jjfaTabFont">解决方案</div>
                <div class="jjfaTabA">
                  <div class="jjfaTab1" v-for="item in solutionList" @click="solution(item)">
                    <div class="jjfatab1">
                      <img :src="item.blackSrc" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">{{item.title}}</div>
                        <div class="jjfatab1Font2">{{item.content}}</div>
                      </div>
                    </div>
                  </div>
<!--                  <div class="jjfaTab1" @click="solution1">-->
<!--                    <div class="jjfatab1">-->
<!--                      <img src="@/assets/jjfatab2.png" alt="" />-->
<!--                    </div>-->
<!--                    <div class="jjfatab1Font">-->
<!--                      <div>-->
<!--                        <div class="jjfatab1Font1">政府数智化</div>-->
<!--                        <div class="jjfatab1Font2">数智化政府</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="jjfaTab1" @click="solution2">-->
<!--                    <div class="jjfatab1">-->
<!--                      <img src="@/assets/jjfatab3.png" alt="" />-->
<!--                    </div>-->
<!--                    <div class="jjfatab1Font">-->
<!--                      <div>-->
<!--                        <div class="jjfatab1Font1">行业数智化</div>-->
<!--                        <div class="jjfatab1Font2">规划行业版图</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="jjfaTab1" @click="solution3">-->
<!--                    <div class="jjfatab1">-->
<!--                      <img src="@/assets/jjfatab4.png" alt="" />-->
<!--                    </div>-->
<!--                    <div class="jjfatab1Font">-->
<!--                      <div>-->
<!--                        <div class="jjfatab1Font1">产业数智化</div>-->
<!--                        <div class="jjfatab1Font2">构建数智化</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="tabmr" :class="winHeight > 0 ? 'tabmrCol' : ''">
            <div class="tabFont">
              <span class="tabFont1" @click="goEnablingTechnology"
                >技术赋能</span
              >
              <div class="tabmr1"></div>
              <div class="jjfaTab">
                <div class="jjfaTabFont">技术赋能</div>
                <div class="jjfaTabA">
                  <div class="jjfaTab1" v-for="item in technologyEnabling" @click="enablingTechnology(item)">
                    <div class="jjfatab1">
                      <img :src="item.blackSrc" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">{{item.title}}</div>
                        <div class="jjfatab1Font2">{{item.remark}}</div>
                      </div>
                    </div>
                  </div>
<!--                  <div class="jjfaTab1" @click="enablingTechnology1">-->
<!--                    <div class="jjfatab1">-->
<!--                      <img src="@/assets/jsfntab2.png" alt="" />-->
<!--                    </div>-->
<!--                    <div class="jjfatab1Font">-->
<!--                      <div>-->
<!--                        <div class="jjfatab1Font1">云计算</div>-->
<!--                        <div class="jjfatab1Font2">分布式计算</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="jjfaTab1" @click="enablingTechnology2">-->
<!--                    <div class="jjfatab1">-->
<!--                      <img src="@/assets/jsfntab3.png" alt="" />-->
<!--                    </div>-->
<!--                    <div class="jjfatab1Font">-->
<!--                      <div>-->
<!--                        <div class="jjfatab1Font1">大数据</div>-->
<!--                        <div class="jjfatab1Font2">大数据分析</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="jjfaTab1" @click="enablingTechnology3">-->
<!--                    <div class="jjfatab1">-->
<!--                      <img src="@/assets/jsfntab4.png" alt="" />-->
<!--                    </div>-->
<!--                    <div class="jjfatab1Font">-->
<!--                      <div>-->
<!--                        <div class="jjfatab1Font1">IOT</div>-->
<!--                        <div class="jjfatab1Font2">物联网时代</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div
            class="tabmr"
            :class="winHeight > 0 ? 'tabmrCol' : ''"
            @click="goProductCase"
          >
            <div class="tabFont tabFont1">产品案例</div>
            <div class="tabmr1"></div>
          </div>
          <div class="tabmr" :class="winHeight > 0 ? 'tabmrCol' : ''">
            <div class="tabFont">
              <span class="tabFont1" @click="goPressCenter">新闻中心</span>
              <div class="tabmr1"></div>
              <div class="jjfaTab">
                <div class="jjfaTabFont">新闻中心</div>
                <div class="jjfaTabA">
                  <div class="jjfaTab1" @click="pressCenter">
                    <div class="jjfatab1">
                      <img src="@/assets/新闻快讯.png" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">新闻快讯</div>
                        <div class="jjfatab1Font2">紧随新闻形势</div>
                      </div>
                    </div>
                  </div>
                  <div class="jjfaTab1" @click="pressCenter1">
                    <div class="jjfatab1">
                      <img src="@/assets/通知公告.png" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">通知公告</div>
                        <div class="jjfatab1Font2">公司最新通知</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabmr" :class="winHeight > 0 ? 'tabmrCol' : ''">
            <div class="tabFont">
              <span class="tabFont1" @click="goAboutUs">关于我们</span>
              <div class="tabmr1"></div>
              <div class="jjfaTab">
                <div class="jjfaTabFont">关于我们</div>
                <!-- <div class="gywmFont">
                  <div class="gywmFont1" @click="aboutUs">公司介绍</div>
                  <div class="gywmFont1" @click="aboutUs1">发展历程</div>
                  <div class="gywmFont1" @click="aboutUs2">公司荣誉</div>
                  <div class="gywmFont1" @click="aboutUs3">合作伙伴</div>
                </div> -->
                <div class="jjfaTabA">
                  <div class="jjfaTab1" @click="aboutUs">
                    <div class="jjfatab1">
                      <img src="@/assets/公司介绍.svg" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">公司介绍</div>
                        <div class="jjfatab1Font2">了解然茜科技</div>
                      </div>
                    </div>
                  </div>
                  <div class="jjfaTab1" @click="aboutUs3">
                    <div class="jjfatab1">
                      <img src="@/assets/发展历程.svg" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">发展历程</div>
                        <div class="jjfatab1Font2">时间见证历程</div>
                      </div>
                    </div>
                  </div>
                  <div class="jjfaTab1" @click="aboutUs1">
                    <div class="jjfatab1">
                      <img src="@/assets/公司荣誉.svg" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">公司荣誉</div>
                        <div class="jjfatab1Font2">见证然茜科技</div>
                      </div>
                    </div>
                  </div>
                  <div class="jjfaTab1" @click="aboutUs2">
                    <div class="jjfatab1">
                      <img src="@/assets/能力体系.svg" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">能力体系</div>
                        <div class="jjfatab1Font2">构建能力体系</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="jjfaTab1" @click="aboutUs4">
                    <div class="jjfatab1">
                      <img src="@/assets/合作伙伴.svg" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">合作伙伴</div>
                        <div class="jjfatab1Font2">实现共赢未来</div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <!-- <div class="jjfaTabA">
                  <div class="jjfaTab1" @click="aboutUs2">
                    <div class="jjfatab1">
                      <img src="@/assets/能力体系.svg" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">能力体系</div>
                        <div class="jjfatab1Font2">构建能力体系</div>
                      </div>
                    </div>
                  </div>
                  <div class="jjfaTab1" @click="aboutUs5">
                    <div class="jjfatab1">
                      <img src="@/assets/加入我们.svg" alt="" />
                    </div>
                    <div class="jjfatab1Font">
                      <div>
                        <div class="jjfatab1Font1">加入我们</div>
                        <div class="jjfatab1Font2">选择正确未来</div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
<!--        <div class="sea" @click="search"></div>-->
        <div @click="search" class="sea1">
          <img src="../assets/sh.svg" style="width: 24px">
        </div>
        <!-- <div class="search">请输入</div> -->
        <div class="search-alert" v-if="isShow">
          <span class="close" @click="close"></span>
          <div class="cent-form">
            <!-- <form action="/search.html"> -->
              <input
                class="fl tex"
                type="text"
                value=""
                placeholder="请输入搜索内容"
                name="search"
                id="skey"
                autocomplete="off"
                v-model="search1"
              />
              <input class="fr sub-btn" type="submit" value="搜索" @click="Sea"/>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
    <div style="width:1px;height:80px"></div>
  </div>
</template>

<script>
import {getOpenSolutionList, getOpenTechnologyEnablingList} from "../api/open";

export default {
  data() {
    return {
      winHeight: 0,
      isShow: false,
      solutionList:[],
      technologyEnabling:[],
      search1:''
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    getOpenSolutionList({}).then(res=>{
      this.solutionList = res.data
      if(this.solutionList!==null){
        this.solutionList.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
          item.blackSrc = process.env.VUE_APP_BASE_API+item.blackSrc
        })
      }
    })
    getOpenTechnologyEnablingList({}).then(res=>{
      this.technologyEnabling = res.data
      if(this.technologyEnabling!==null){
        this.technologyEnabling.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
          item.blackSrc = process.env.VUE_APP_BASE_API+item.blackSrc
        })
      }
    })
  },
  methods: {
    Sea() {
      if(this.search1 != '') {
        this.$router.push({ name: "search",query:{title: this.search1} });
        this.isShow = false;
        this.search1 = ''
      } else {
        this.$message({
          message: '请输入搜索内容',
          type: 'warning'
        });
      }
    },
    search() {
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
    handleScroll(e) {
      this.winHeight = e.target.scrollTop || document.documentElement.scrollTop;
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
    goSolution() {
      getOpenSolutionList({}).then(res=>{
        if(res.data!==null && res.data.length>0){
          this.$router.push({path:'solution',query:{solutionId:res.data[0].id}})
        }
      })
    },
    goEnablingTechnology() {
      if(this.technologyEnabling && this.technologyEnabling.length>0){
        this.$router.push({path: "enablingTechnology",query:{enablingId:this.technologyEnabling[0].id}});
      }
    },
    goProductCase() {
      this.$router.push({ name: "productCase" });
    },
    goPressCenter() {
      this.$router.push({ name: "pressCenter" });
    },
    goAboutUs() {
      this.$router.push({ name: "aboutUs" });
    },
    solution(row) {
      if(row.title === "社会服务"){
        this.$router.push({path:'solution',query:{solutionId:row.id}})
      }
      if(row.title === "政府数字化"){
        this.$router.push({path:'solution1',query:{solutionId:row.id}})
      }
      if(row.title === "行业数字化"){
        this.$router.push({path:'solution2',query:{solutionId:row.id}})
      }
      if(row.title === "产品数字化"){
        this.$router.push({path:'solution3',query:{solutionId:row.id}})
      }
    },
    solution1() {
      this.$router.push({ name: "solution1" });
    },
    solution2() {
      this.$router.push({ name: "solution2" });
    },
    solution3() {
      this.$router.push({ name: "solution3" });
    },
    enablingTechnology(row) {
      this.$router.push({ path: "enablingTechnology",query:{enablingId:row.id} });
    },
    enablingTechnology1() {
      this.$router.push({ name: "enablingTechnology1" });
    },
    enablingTechnology2() {
      this.$router.push({ name: "enablingTechnology2" });
    },
    enablingTechnology3() {
      this.$router.push({ name: "enablingTechnology3" });
    },
    pressCenter() {
      this.$router.push({ name: "pressCenter" });
    },
    pressCenter1() {
      this.$router.push({ name: "pressCenter1" });
    },
    aboutUs() {
      this.$router.push({ name: "aboutUs" });
    },
    aboutUs1() {
      this.$router.push({ name: "aboutUs1" });
    },
    aboutUs2() {
      this.$router.push({ name: "aboutUs2" });
    },
    aboutUs3() {
      this.$router.push({ name: "aboutUs3" });
    },
    aboutUs4() {
      this.$router.push({ name: "aboutUs4" });
    },
    aboutUs5() {
      this.$router.push({ name: "aboutUs5" });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.head1 {
  /* width: 1920px; */
  width: 100%;
  height: 80px;
  /* box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2); */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
}
.headContent {
  width: 1200px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.headBackground {
  background: #fff;
}
.headLogo {
  padding: 22px 0 0 24px;
  /* background-image: url('../assets/headLogoW.png');
  background-repeat: no-repeat */
}
.headTab {
  margin-left: 196px;
  font-size: 18px;
  align-self: center;
  display: flex;
}
/* .tabFont {
  cursor: pointer;
} */
.tabFont1 {
  cursor: pointer;
}
.tabmr:hover .jjfaTab {
  display: block;
}
.tabmr {
  /* cursor: pointer; */
  margin-right: 32px;
  font-size: 18px;
  color: #7d7d7d;
  line-height: 80px;
  letter-spacing: 1px;
  height: 80px;
}
.tabmrCol {
  color: #7d7d7d;
}
/* .tabmr:focus {
  color: #183f85;
}
.tabmr:hover {
  color: #183f85;
} */
.tabmr:hover .tabmr1 {
  background: linear-gradient(90deg, #fff 0%, #94a7d0 100%);
}
.tabmr1 {
  height: 3px;
  /* background: linear-gradient(90deg, #183f85 0%, #94a7d0 100%); */
  position: relative;
  top: -3px;
}
.jjfaTab {
  width: 1200px;
  height: 185px;
  background: #fcfcfc;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 80px;
  transform: translate(-50%);
  left: 50%;
  z-index: 100;
  display: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.jjfaTabFont {
  font-size: 24px;
  color: #183f85;
  line-height: 33px;
  letter-spacing: 1px;
  margin: 24px 0 24px 24px;
}
.jjfaTabA {
  display: flex;
}
.jjfaTab1 {
  width: 226px;
  height: 80px;
  background: rgba(211, 225, 255,0.2);
  border-radius: 8px;
  margin: 0 0 24px 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.5s;
}
.jjfaTab1:hover{
  transform: scale(1.15);
}
.jjfatab1 {
  margin: 10px 0 10px 12px;
}
.jjfatab1Font {
  display: flex;
  margin-right: 16px;
}
.jjfatab1Font1 {
  margin: 16px 0 4px 0;
  font-size: 20px;
  color: #183f85;
  line-height: 28px;
  letter-spacing: 1px;
  display: flex;
  justify-content: end;
}
.jjfatab1Font2 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #7d7d7d;
  line-height: 22px;
  letter-spacing: 1px;
  display: flex;
  justify-content: end;
}
.search {
  width: 190px;
  height: 32px;
  color: #7d7d7d;
  border: 1px solid #999;
  align-self: center;
  padding: 5px 0 0 8px;
  border-radius: 20px;
}
.gywmTab {
  width: 1200px;
  height: 300px;
  background: #fcfcfc;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 80px;
  transform: translate(-50%);
  left: 50%;
  display: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.gywmFont {
  font-size: 18px;
  color: #7d7d7d;
  line-height: 25px;
  letter-spacing: 1px;
  display: flex;
  margin: 24px 24px 50px 24px;
}
.gywmFont1 {
  margin-right: 24px;
  cursor: pointer;
}
.sea1 {
  float: right;
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 100px;
}
.sea {
  width: 80px;
  height: 81px;
  background: url(http://www.insigma.com.cn/web/bocweb/web/css/../img/search.png)
    no-repeat center center #005bac;
  /* background-image: url("../assets/搜索灰.svg"); */
  background-repeat: no-repeat;
  float: right;
  cursor: pointer;
}
.search-alert {
  position: fixed;
  z-index: 1000;
  background-color: #222222;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* display: none; */
}
.search-alert .close {
  display: block;
  width: 24px;
  height: 24px;
  background: url(./images/close.png) no-repeat center center;
  position: absolute;
  top: 38px;
  right: 2.6%;
  transition: transform 0.4s ease;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .search-alert .close:hover {
    transform: rotate(180deg);
  }
}
@media (max-width: 1024px) {
  .search-alert .close {
    top: 20px;
    right: 20px;
  }
}
.search-alert .cent-form {
  width: 80%;
  height: 124px;
  position: absolute;
  left: 50%;
  margin-left: -40%;
  top: 50%;
  margin-top: -62px;
  border-bottom: 1px solid #515151;
}
.search-alert .cent-form .tex {
  outline: none;
  border: none;
  font-size: 30px;
  height: 124px;
  line-height: 124px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  color: #555;
  background: none;
  padding-left: 88px;
  padding-right: 80px;
  background: url(./images/img5.png) no-repeat 35px center;
}
.search-alert .cent-form .sub-btn {
  border: none;
  height: 124px;
  font-size: 24px;
  line-height: 124px;
  color: #555;
  text-align: right;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
</stylescoped>
